<template>
    <div class="box_div back_div">
        <p class="info_title" @click="handleTitleClick">
            箱动态【{{
                boatInfo.vesselEname
                    ? boatInfo.vesselEname
                    : boxInfo.vesselsName
            }}&ensp;/&ensp;{{
                boatInfo.voyage ? boatInfo.voyage : boxInfo.voyage
            }}】
            <span v-if="showTab==='NB'">【堆存天数：{{ storageDays }} 天】</span>
        </p>

        <div>
            <el-descriptions class="margin-top" :column="3" border>
                <el-descriptions-item
                    v-for="key in loopKey"
                    :key="headers[key].label"
                    :content-class-name="[
                        {
                            pink_cell:
                                (headers[key].label == '货重' &&
                                    errorFlag == 1) ||
                                (headers[key].label == '累计总数' &&
                                    errorFlag == 1),
                        },
                    ]"
                >
                    <template slot="label">
                        {{ headers[key].label }}
                    </template>
                    {{ headers[key].prop }}
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
</template>
<script>
import {detailContentShow} from "./detailContentShow"
export default {
    name: "DetailContentBox",
    props: {
        // 当前箱
        activePosition: {
            type: Number,
        },
        // 当前tab
        showTab: {
            type: String,
        },
        boxInfo: {
            type: Object,
            default: () => {},
        },
        boatInfo: {
            type: Object,
            default: () => {},
        },
        errorFlag: {
            type: Number,
            default: 0,
        },
        headers: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            // 海关放行信息
            checkInfoList: [],
            // 堆积天数
            storage: "--",
        };
    },
    computed: {
        loopKey() {
            return Object.keys(detailContentShow({
                tab:this.showTab,
                type:1
            }));
        },
        // 计算堆积天数
        storageDays() {
            if (this.headers?.loadTime?.prop && this.headers?.costcoTime?.prop) {
                let b_t = new Date(
                    this.headers.loadTime.prop?.split(" ")[0],
                ).getTime();
                let s_t = new Date(
                    this.headers.costcoTime.prop?.split(" ")[0],
                ).getTime();
                return (b_t - s_t) / 3600000 / 24 + 1;
            } else {
                return "--";
            }
        },
    },
    methods: {
        handleTitleClick() { 
            document.querySelector('#logistics-detail-box').scrollIntoView();
        },
    },
};
</script>
<style scoped>
.box_div {
    background: #fff;
    padding: 2rem;
    margin-bottom: 2rem;
}

.info_title {
    display: block;
    width: fit-content;
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0 0 1rem;
    color: #409EFF;
}

.info_title:before {
    content: "";
    height: 2rem;
    margin-top: 12px;
    margin-right: 1rem;
    border-left: 2px solid #409EFF;
}
.back_div {
    background: #f1f3f6;
    border-radius: 4px;
    box-shadow: inset 0 0 15px rgba(55, 84, 170, 0),
        inset 0 0 20px rgba(255, 255, 255, 0),
        10px 10px 18px rgba(55, 84, 170, 0.15), -10px -10px 22px white,
        inset 0px 0px 4px rgba(255, 255, 255, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    outline: none;
    border: none;
}
.back_div:hover .draw {
    opacity: 0.4;
}

p {
    margin: 0;
}
</style>
