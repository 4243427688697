<template>
    <div class="detail-customs-release box_div back_div" id="logistics-detail-release">
        <p class="info_title2">海关放行信息</p>
        <el-table
            border
            :data="checkInfoList"
            style="width: 100%"
            :header-cell-style="{
                background: '#eef1f6',
                color: '#606266',
            }"
        >
            <el-table-column
                prop="data_1"
                label="报关单号"
            ></el-table-column>
            <el-table-column
                prop="data_2"
                label="海放时间"
                v-if="[ 'NB','XM'].includes(showTab)"
            >
                <template slot-scope="scope">
                    <p>{{ splitDateAndTime(scope.row.data_2).date }}</p>
                    <p>{{ splitDateAndTime(scope.row.data_2).time }}</p>
                </template>
            </el-table-column>
            <el-table-column
                prop="data_5"
                label="清洁舱单"
                v-if="showTab === 'NB'"
            >
                <template slot-scope="scope">
                    <el-image
                        :src="scope.row.data_5 ? icon_green : icon_red"
                        class="passIcon"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column
                prop="data_9"
                label="码头运抵"
                v-if="showTab === 'NB'"
            >
                <template slot-scope="scope">
                    <el-image
                        :src="scope.row.data_9 ? icon_green : icon_red"
                        class="passIcon"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column label="海放状态">
                <template slot-scope="scope">
                    <el-image
                        :src="scope.row.data_3 ? icon_green : icon_red"
                        class="passIcon"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column
                prop="data_12"
                label="海放反馈"
                v-if="showTab === 'SH'"
            ></el-table-column>
            <el-table-column
                prop="data_4"
                label="三联单"
                v-if="showTab === 'NB'"
            >
                <template slot-scope="scope">
                    <el-image
                        :src="scope.row.data_4 ? icon_green : icon_red"
                        class="passIcon"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="data_10" label="码头放行">
                <template slot-scope="scope">
                    <p
                        :class="[
                            { p_green: scope.row.data_10 === 'Y' },
                            { p_red: scope.row.data_10 === 'N' },
                        ]"
                        v-if="showTab!=='XM'"
                    >
                        {{
                            passFlag2String(
                                scope.row.data_10,
                                showTab === "SH",
                            )
                        }}
                    </p>
                    <p v-else-if="showTab==='XM'" :class="[
                            { p_green: scope.row.data_10 == '1' },
                            { p_red: scope.row.data_10 == '0' },
                        ]">
{{
                            passFlag2StringXM(
                                scope.row.data_10,
                            )
                        }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                prop="data_11"
                label="码头反馈"
                v-if="['NB','XM'].includes(showTab)"
            ></el-table-column>
            <el-table-column
                prop="data_6"
                label="海关查验状态"
                v-if="showTab === 'NB'"
            ></el-table-column>
            <el-table-column
                prop="data_7"
                label="海关操作时间"
                v-if="showTab === 'NB'"
            ></el-table-column>
            <el-table-column
                prop="data_8"
                label="986查验（机检）"
                v-if="showTab === 'NB'"
            ></el-table-column>
        </el-table>
    </div>
</template>

<script>
import {
    getCheck986Str,
    passFlag2String,
    splitDateAndTime,
    getString2JsonArr,
    getCheckStatusStr,
    passFlag2StringXM
} from "@/utils";
import {
    checkInfoList1,
} from "@/utils/dataList";
export default {
    name: "DetailCustomsRelease",
    props: {
        // 当前tab
        showTab: {
            type: String,
        },
        boxInfo: {
            type: Object,
            default: () => {},
        },
        // 当前箱
        activePosition: {
            type: Number,
        },
    },
    data() {
        return {
            // 海关放行信息
            checkInfoList: [],
            icon_green: require("@/assets/img/green.png"),
            icon_red: require("@/assets/img/red.png"),
            getCheck986Str,
            passFlag2String,
            splitDateAndTime,
            getString2JsonArr,
            getCheckStatusStr,
            passFlag2StringXM
        }
    },
    computed: {
        curBox() {
            const type =
                this.showTab === "NB"
                    ? "nppInGateEmptyVoList"
                    : "ctnEmptyVoList";
            return this.boxInfo[type]
                ? this.boxInfo[type][this.activePosition]
                : {};
        },
    },
    watch: {
        activePosition: {
            handler(val) {
                this.init();
            },
            deep: true,
            immediate: true,
        },
        boxInfo: {
            handler(val) {
                this.init();
            },
            deep: true,
        },
    },
    methods: {
        // 判断某个值是否为Y
        checkValueIsY(value) {
            return value === "Y";
        },
        init() {
            //海关放行信息
            this.checkInfoList = [...checkInfoList1];
            const box = this.curBox;
            if (this.showTab === "NB") {
                if (box && box.npp) {
                    var box_flag_list = getString2JsonArr(box.npp?.bills);
                    box_flag_list.forEach((item) => {
                        if (item && item.billno === box.npp?.blno) {
                            this.checkInfoList[0].data_3 = this.checkValueIsY(
                                item.custom_flag,
                            ); //海放状态
                            this.checkInfoList[0].data_4 = this.checkValueIsY(
                                item.sld_flag,
                            ); //三联舱单
                            this.checkInfoList[0].data_5 = this.checkValueIsY(
                                item.ifcsum_flag,
                            ); //清洁舱单
                        }
                    });
                    this.checkInfoList[0].data_9 = this.checkValueIsY(
                        box.npp.cpcodeFlag,
                    ); //海放栏-码头运抵
                    this.checkInfoList[0].data_10 = box.npp.passFlag; //海放栏-码头放行状态
                    this.checkInfoList[0].data_11 = box.npp.remark; //海放栏-码头反馈
                } else {
                    this.checkInfoList[0].data_3 = false; //海放状态
                    this.checkInfoList[0].data_4 = false; //三联舱单
                    this.checkInfoList[0].data_5 = false; //清洁舱单
                    this.checkInfoList[0].data_9 = false; //海放栏-码头运抵
                    this.checkInfoList[0].data_10 = "-"; //海放栏-码头放行状态
                    this.checkInfoList[0].data_11 = "-"; //海放栏-码头反馈
                }
                if (box && box.ctn) {
                    this.checkInfoList[0].data_1 = "-"; //报关单号
                    this.checkInfoList[0].data_2 = box.ctn.customPassTime; //海放时间
                    if (box.npp !== null) {
                        this.checkInfoList[0].data_3 = this.checkValueIsY(
                            box.npp.customFlag,
                        ); //海放状态
                    }
                    this.checkInfoList[0].data_6 = getCheckStatusStr(
                        box.ctn.checkStatus,
                    ); //海关查验状态
                    this.checkInfoList[0].data_7 = "-"; //海关操作时间
                    this.checkInfoList[0].data_8 = getCheck986Str(
                        box.ctn.checkType,
                    );
                } else {
                    this.checkInfoList[0].data_2 = "-";
                    this.checkInfoList[0].data_6 = "-";
                    this.checkInfoList[0].data_8 = "-";
                }
            } else if(this.showTab==='XM'){
                if (box && box.ctn) {
                    this.checkInfoList[0].data_1 = "-"; //报关单号
                    this.checkInfoList[0].data_3 =box.ctn.isCustomPass == "1"; // 海放状态
                    this.checkInfoList[0].data_10 = box.ctn.isPortPass; //码放状态
                    this.checkInfoList[0].data_2 = box.ctn.customPassTime; //海放时间
                }
                if(this.boxInfo.blnoInfo){
                    this.checkInfoList[0].data_11=this.boxInfo.blnoInfo.wharfRemark; //码头反馈
                }
                
            }
            
            else {
                if (box && box.ctn) {
                    this.checkInfoList[0].data_1 = "-"; //报关单号
                    this.checkInfoList[0].data_3 =
                        box.ctn.customPassFlag === "1"; // 海放状态
                    this.checkInfoList[0].data_10 = box.ctn.portPassFlag; //码放状态
                    this.checkInfoList[0].data_12 = box.ctn.customPassResult; //海放反馈
                } else {
                    this.checkInfoList[0].data_3 = false; //海放状态
                    this.checkInfoList[0].data_10 = "-"; //码放状态
                    this.checkInfoList[0].data_12 = "-"; //海放反馈
                }
            }
        },
    }
}
</script>

<style lang="stylus" scoped>
.box_div {
    background: #fff;
    padding: 2rem;
    margin-bottom: 2rem;
}

.back_div {
    background: #f1f3f6;
    border-radius: 4px;
    box-shadow: inset 0 0 15px rgba(55, 84, 170, 0),
        inset 0 0 20px rgba(255, 255, 255, 0),
        10px 10px 18px rgba(55, 84, 170, 0.15), -10px -10px 22px white,
        inset 0px 0px 4px rgba(255, 255, 255, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    outline: none;
    border: none;
}
.back_div:hover .draw {
    opacity: 0.4;
}

.p_green {
    color: var(--GREEN-1E9855);
}

.p_red {
    color: var(--RED-C11C20);
}

.info_title2 {
    display: block;
    width: fit-content;
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0 1rem;
    color: #409EFF;
    cursor: pointer;
    a {
        user-select: none;
    }
}

.info_title2:before {
    content: "";
    height: 2rem;
    margin-top: 12px;
    margin-right: 1rem;
    border-left: 2px solid #409EFF;
}

.passIcon {
    width: 3rem;
    height: 3rem;
    background-size: 100% 100%;
}

p {
    margin: 0;
}
</style>