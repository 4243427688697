<template>
    <!-- 修改船名航次 -->
    <ShipDialog
        :title="title"
        :visible.sync="dialogTableVisible"
        :showTab="showTab"
        center
        append-to-body
    >
        <div class="ticket_div">
            <el-autocomplete
                clearable
                @clear="blurForBug()"
                class="inline-input input-with-select"
                v-model="vesselsName"
                @input="checkVesselename()"
                @change="checkFinalVname"
                :fetch-suggestions="shipSearch"
                placeholder="请输入船名"
                :trigger-on-focus="false"
                @select="handleShipSelect"
            ></el-autocomplete>
            <el-input
                clearable
                class="bottom_div"
                placeholder="请输入航次"
                v-model="voyage"
                @input="checkVoyage"
            ></el-input>
        </div>
        <DialogFooter
            :showTab="showTab"
            @confirm="addRemark"
            @cancel="closeDialog"
            :loading="loading"
        ></DialogFooter>
    </ShipDialog>
</template>
<script>
import { searchShip } from "@/api/boxQuery";
import { logisticsRequest } from "@/views/Logistics/logistics";
import {
    apiCallback,
    freeBalanceLackTip,
    getUserAccountInfo,
} from "../../utils";
import { getCompanyId, getRole } from "@/utils/auth";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter";
import { verFreeBalanceEn } from "@/api/tracking";
export default {
    props: ["showTab"],
    data() {
        return {
            test: "测试信息",
            title: "",
            vesselsName: "",
            voyage: "",
            oldVesselsName: "",
            oldVoyage: "",
            dialogTableVisible: false,
            item: [],
            totalshipList: [],
            ids: [],
            loading: false,
        };
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
    mounted() {},
    methods: {
        init(item) {
            this.dialogTableVisible = true;
            if (item[0].vesselsName || item[0].voyage || item.vessels) {
                this.title = "修改船名/航次";
                this.vesselsName = item[0].vesselsName;
                this.oldVesselsName = this.vesselsName;
                this.voyage = item[0].voyage;
                this.oldVoyage = this.voyage;
            } else {
                this.title = "添加船名/航次";
                this.vesselsName = "";
                this.oldVesselsName = "";
                this.voyage = "";
                this.oldVoyage = "";
            }
            this.item = item;
            const ids = item[0].sonList
                ? [item[0].id, ...item[0].sonList.map((son) => son.id)]
                : [item[0].id];
            this.ids = ids;
        },
        // 更新船名航次
        updateShipVoyage() {
            try {
                var that = this;
                const reqFunc = logisticsRequest({
                    tab: this.showTab,
                    type: 6,
                });
                this.loading = true;
                reqFunc({
                    ids: this.ids,
                    vesselsName: this.vesselsName,
                    voyage: this.voyage,
                    role: getRole(),
                    companyId: getCompanyId(),
                })
                    .then(function () {
                        that.$message.success("修改成功！");
                        that.$emit("refreshDataList", that.item[0].id);
                        that.closeDialog();
                        that.loading = false;
                        getUserAccountInfo(true);
                    })
                    .catch((err) => {
                        console.error(err);
                        that.closeDialog();
                        that.loading = false;
                    });
            } catch (e) {
                console.log(e, "e");
            }
        },
        addRemark() {
            if (this.item.length) {
                if (!this.vesselsName || !this.voyage) {
                    this.$message.error("船名航次不能为空");
                    return;
                }
                if (
                    this.vesselsName === this.oldVesselsName &&
                    this.voyage === this.oldVoyage
                ) {
                    this.$message.warning("请输入新的船名和航次");
                    return;
                }
            }
            if (!["TJ", "QD", "XM"].includes(this.showTab)) {
                freeBalanceLackTip(this.showTab, this.updateShipVoyage);
            } else {
                this.updateShipVoyage();
            }
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },
        //全部船名航次搜索框带输入建议
        shipSearch(queryString, cb) {
            var restaurants = this.totalshipList;
            var results = queryString
                ? restaurants.filter(this.shipFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            // console.log(results)
            cb(results);
        },
        shipFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value.indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        //订阅框船名航次搜索框选择了输入建议
        handleShipSelect(item) {
            var selectShip = item.value.split("/");
            this.vesselsName = selectShip[0];
            this.voyage = selectShip[1];
        },
        //检查船名
        checkVesselename() {
            if (this.vesselsName) {
                this.vesselsName = this.setRightForrmatSpace2(this.vesselsName);
            }
            if (this.vesselsName.length == 4) {
                var that = this;
                searchShip({ vesselsName: this.vesselsName })
                    .then(function (response) {
                        that.totalshipList = [];
                        const data = apiCallback(response);
                        data.forEach((item) => {
                            var obj = {};
                            obj.value = item.s + "/" + item.v;
                            that.totalshipList.push(obj);
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else if (this.vesselsName.length < 4) {
                this.totalshipList = [];
            }
        },
        //去掉船名多余的元素
        checkFinalVname() {
            if (this.vesselsName) {
                if (this.vesselsName[this.vesselsName.length - 1] == ".") {
                    this.vesselsName = this.vesselsName.substr(
                        0,
                        this.vesselsName.length - 1,
                    );
                }
                if (this.vesselsName[this.vesselsName.length - 1] == "/") {
                    this.vesselsName = this.vesselsName.substr(
                        0,
                        this.vesselsName.length - 1,
                    );
                }
                var list = this.vesselsName.split(" ");
                let newList = "";
                list.forEach((item, index) => {
                    if (item) {
                        if (index == 0) {
                            newList = item;
                        } else {
                            newList = newList + " " + item;
                        }
                    }
                });
                this.vesselsName = newList;
            }
        },
        //检查航次
        checkVoyage() {
            if (this.voyage) {
                this.voyage = this.setRightForrmat(this.voyage);
            }
        },
        //解决el-autocomplete 点击clearable后再输入无法显示建议列表的bug
        blurForBug() {
            document.activeElement.blur();
        },
        //判断输入内容只能是数字和字母，且字母自动转大写
        setRightForrmat(val) {
            if (val) {
                let codeReg = new RegExp("[A-Za-z0-9]+"), //正则 英文+数字；
                    len = val.length,
                    str = "";
                for (var i = 0; i < len; i++) {
                    if (codeReg.test(val[i])) {
                        str += val[i].toUpperCase();
                    }
                }
                val = str;
                return val;
            }
        },
        setRightForrmatSpace2(val) {
            // console.log("val=", val)
            if (val) {
                let codeReg = new RegExp("[A-Za-z0-9 -/.]+"), //正则 英文+数字；
                    len = val.length,
                    str = "",
                    codeReg2 = new RegExp("^(?![.]).*");
                for (var i = 0; i < len; i++) {
                    if (codeReg.test(val[i]) && codeReg2.test(val)) {
                        if (codeReg.test(val[i]) && codeReg2.test(val)) {
                            if (val[i] == "/" && val[i - 1] == ".") {
                                str = str.substring(0, i - 1);
                            }
                            if (val[i] == "/" && val[i - 1] == "/") {
                                str = str.substring(0, i - 1);
                            }
                            if (val[i] == "." && val[i - 1] == ".") {
                                str = str.substring(0, i - 1);
                            }
                            if (val[i] == "." && val[i - 1] == "/") {
                                str = str.substring(0, i - 1);
                            }
                            str += val[i].toUpperCase();
                        }
                    }
                }
                val = str;
                return val;
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.ticket_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  .el-autocomplete {
    width: 100%;
  }
}

.el-image {
  width: 30rem;
  height: 30rem;
}

.bottom_div {
  margin-top: 2rem;
}
</style>
