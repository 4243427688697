<template>
    <!-- 添加备注 -->
    <ShipDialog
        :title="title"
        :visible.sync="dialogTableVisible"
        :showTab="showTab"
        width="500px"
        size="small"
        center
        append-to-body
        :show-close="false"
        :needImg="false"
    >
        <div class="ticket_div">
            <el-input
                type="textarea"
                maxlength="40"
                show-word-limit
                placeholder="请输入内容"
                v-model="textarea"
            ></el-input>
        </div>
        <DialogFooter
            :showTab="showTab"
            @confirm="addRemark"
            @cancel="closeDialog"
            :loading="confirmLoading"
        ></DialogFooter>
    </ShipDialog>
</template>
<script>
import { updateRemarkAndTruck, remarkShVessels } from "@/api/boxQuery";
import { shipRequest } from "@/views/ShipSubscrib/ship.js";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter.vue";
export default {
    props: ["showTab"],
    data() {
        return {
            test: "测试信息",
            title: "",
            textarea: "",
            dialogTableVisible: false,
            id: "",
            resultid: "",
            blno: "",
            oldremark: "",
            confirmLoading: false,
        };
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
    mounted() {},
    methods: {
        init({ id, blno, vesselename, voyage, remark }) {
            this.dialogTableVisible = true;
            if (remark) {
                this.title = "修改备注";
            } else {
                this.title = "添加备注";
            }
            this.textarea = remark;
            this.oldremark = remark;
            this.id = id;
            this.blno = blno;
            this.vesselename = vesselename;
            this.voyage = voyage;
        },
        addRemark() {
            // if (this.textarea) {
            if (this.oldremark == this.textarea) {
                this.$message.warning("请输入新的备注");
                return;
            }
            const that = this;
            this.confirmLoading = true;
            // 宁波船期改备注
            const param = {
                remark: this.textarea,
                id: this.id,
            };

            // 上海船期改备注
            const paramSH = new FormData();
            paramSH.append("remark", this.textarea);
            paramSH.append("id", this.id);

            const reqFunc = shipRequest({
                tab: this.showTab,
                type: 7,
            });
            reqFunc(this.showTab === "NB" ? param : paramSH)
                .then(() => {
                    that.$message.success("修改成功");
                    that.$parent.remark = that.textarea;
                    that.$emit("getData");
                    that.$emit("refreshDataList");
                    that.closeDialog();
                })
                .finally(() => {
                    this.confirmLoading = false;
                });
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },
    },
};
</script>
<style lang="stylus" scoped>
.ticket_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-image {
  width: 30rem;
  height: 30rem;
}
</style>
