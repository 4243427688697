export const checkInfoList1 = [
    {
        label_1: "报关单号",
        data_1: "",
        label_2: "海放时间",
        data_2: "",
        label_5: "清洁舱单",
        data_5: "",
        label_9: "码头运抵",
        data_9: "",
        label_3: "海放状态",
        data_3: "",
        label_4: "三联舱单",
        data_4: "",
        label_10: "码头放行",
        data_10: "",
        label_11: "码头反馈",
        data_11: "",
        label_6: "海关查验状态",
        data_6: "",
        label_7: "海关操作时间",
        data_7: "",
        label_8: "986查验（机检）",
        data_8: "",
    },
];

export const headers1 = [
    {
        prop: "",
        label: "箱号",
    },
    {
        prop: "",
        label: "铅封",
    },
    {
        prop: "",
        label: "装/卸货港",
    },
    {
        prop: "",
        label: "箱型",
    },
    {
        prop: "",
        label: "箱主",
    },
    {
        prop: "",
        label: "箱状态",
    },
    {
        prop: "",
        label: "件数",
    },
    {
        prop: "",
        label: "体积",
    },
    {
        prop: "",
        label: "货重",
    },
    {
        prop: "",
        label: "箱货重",
    },
    {
        prop: "",
        label: "预录入时间",
    },
    {
        prop: "",
        label: "进场时间",
    },
    {
        prop: "",
        label: "进场集卡",
    },
    {
        prop: "",
        label: "码头放行状态",
    },
    {
        prop: "",
        label: "码头放行时间",
    },
    {
        prop: "",
        label: "码头运抵报告",
    },
    {
        prop: "",
        label: "装船时间",
    },
    // 提空箱暂时隐藏，勿删
    // {
    //   prop: "",
    //   label: "提箱时间",
    // },
    // {
    //   prop: "",
    //   label: "提箱地点",
    // },
    {
        prop: "",
        label: "累计总数",
    },
];

// 船期时间选择
export const timeTypeOptions = [
    {
        label: "预计抵港时间",
        value: "eta_arrival_time",
    },
    {
        label: "预计离港时间",
        value: "eta_departure_time",
    },
    {
        label: "实际抵港时间",
        value: "arrival_time",
    },
    {
        label: "实际离港时间",
        value: "departure_time",
    },
    {
        label: "截单时间",
        value: "port_close_time",
    },
    {
        label: "截关时间",
        value: "close_time",
    },
    {
        label: "进箱开始时间",
        value: "ctn_apply_begin_time",
    },
    {
        label: "进箱截止时间",
        value: "ctn_apply_end_time",
    },
];
// 上海船期时间选择
export const timeTypeOptionsSH = [
    {
        label: "预计抵港时间",
        value: "eta_arrival_time",
    },
    {
        label: "预计离港时间",
        value: "eta_departure_time",
    },
    {
        label: "实际抵港时间",
        value: "arrival_time",
    },
    {
        label: "实际离港时间",
        value: "departure_time",
    },
    {
        label: "进箱开始时间",
        value: "ctn_apply_begin_time",
    },
    {
        label: "进箱截止时间",
        value: "ctn_apply_end_time",
    },
];
// 青岛船期时间选择
export const timeTypeOptionsQD = [
    {
        label: "预计抵港时间",
        value: "eta_arrival_time",
    },
    {
        label: "预计离港时间",
        value: "eta_departure_time",
    },
    {
        label: "收箱开始时间",
        value: "ctn_apply_begin_time",
    },
    {
        label: "收箱截止时间",
        value: "ctn_apply_end_time",
    },
    {
        label: "集港开始(开港)时间",
        value: "jg_apply_begin_time",
    },
    {
        label: "集港结束(截港)时间",
        value: "jg_apply_end_time",
    },
];

// 天津船期时间选择
export const timeTypeOptionsTJ = [
    // jgApplyBeginTime
    {
        label: "集港开始(开港)时间",
        value: "jg_apply_begin_time",
    },
    {
        label: "集港结束(截港)时间",
        value: "jg_apply_end_time",
    },
];

// 厦门船期时间选择
export const timeTypeOptionsXM=[
    {
        label: "预计抵港时间",
        value: "eta_arrival_time",
    },
    {
        label: "预计离港时间",
        value: "eta_departure_time",
    },
    {
        label: "实际抵港时间",
        value: "arrival_time",
    },
    {
        label: "实际离港时间",
        value: "departure_time",
    },
    {
        label: "截单时间",
        value: "port_close_time",
    },
    {
        label: "截关时间",
        value: "close_time",
    },
    {
        label: "进箱开始时间",
        value: "ctn_apply_begin_time",
    },
    {
        label: "进箱截止时间",
        value: "ctn_apply_end_time",
    },
]

// 物流跟踪时间选择
export const orderTimeTypeOptions = [
    {
        label: "装船时间",
        value: "b.load_time",
    },
    ...timeTypeOptions.map((item) => ({ ...item, value: `c.${item.value}` })),
];
// 上海物流跟踪时间选择
export const orderTimeSH = [
    ...timeTypeOptionsSH.map((item) => ({ ...item, value: `c.${item.value}` })),
];
// 青岛物流跟踪时间选择
export const orderTimeQD = [
    ...timeTypeOptionsQD.map((item) => ({ ...item, value: `c.${item.value}` })),
];
// 天津物流跟踪时间选择
export const orderTimeTJ = [
    ...timeTypeOptionsTJ.map((item) => ({ ...item, value: `c.${item.value}` })),
];
// 厦门物流跟踪时间选择
export const orderTimeXM = [
    ...timeTypeOptionsXM.map((item) => ({ ...item, value: `c.${item.value}` })),
]
// 宁波
export const ctnDynamicHeader = {
    ctnno: {
        prop: "",
        label: "箱号",
    },
    sealno: {
        prop: "",
        label: "铅封",
    },
    dlPortCode: {
        prop: "",
        label: "装/卸货港",
    },
    type: {
        prop: "",
        label: "箱型",
    },
    ctnOwner: {
        prop: "",
        label: "箱主",
    },
    boxStatus: {
        prop: "",
        label: "箱状态",
    },
    packageNum: {
        prop: "",
        label: "件数",
    },
    measure: {
        prop: "",
        label: "体积",
    },
    weight: {
        prop: "",
        label: "货重",
    },
    grossWeight: {
        prop: "",
        label: "箱货重",
    },
    costcoTime: {
        prop: "",
        label: "预录入时间",
    },
    inGateTime: {
        prop: "",
        label: "进场时间",
    },
    truckLicense: {
        prop: "",
        label: "进场集卡",
    },
    passFlag: {
        prop: "",
        label: "码头放行状态",
    },
    receiveTime: {
        prop: "",
        label: "码头放行时间",
    },
    cpcodeFlag: {
        prop: "",
        label: "码头运抵报告",
    },
    loadTime: {
        prop: "",
        label: "装船时间",
    },
    // outdoor_time: {
    //     prop: "",
    //     label: "提空箱时间",
    // },
    // yardname: {
    //     prop: "",
    //     label: "提空箱地点",
    // },
    total: {
        prop: "",
        label: "累计总数",
    },
};
// 上海
export const ctnDynamicHeaderSH = {
    ctnno: {
        prop: "",
        label: "箱号",
    },
    sealno: {
        prop: "",
        label: "铅封",
    },
    dlPortCode: {
        prop: "",
        label: "装/卸货港",
    },
    type: {
        prop: "",
        label: "箱型",
    },
    ctnOwner: {
        prop: "",
        label: "箱主",
    },
    boxStatus: {
        prop: "",
        label: "箱状态",
    },
    packageNum: {
        prop: "",
        label: "件数",
    },
    measure: {
        prop: "",
        label: "体积",
    },
    weight: {
        prop: "",
        label: "货重",
    },
    grossWeight: {
        prop: "",
        label: "箱货重",
    },
    inGateTime: {
        prop: "",
        label: "进场时间",
    },
    total: {
        prop: "",
        label: "累计总数",
    },
};

// 青岛
export const ctnDynamicHeaderQD = {
    ctnno: {
        prop: "",
        label: "箱号",
    },
    sealno: {
        prop: "",
        label: "铅封",
    },
    destinationPort: {
        prop: "",
        label: "目的港",
    },
    type: {
        prop: "",
        label: "箱型",
    },
    // ctnOwner: {
    //     prop: "",
    //     label: "箱主",
    // },
    // boxStatus: {
    //     prop: "",
    //     label: "箱状态",
    // },
    packageNum: {
        prop: "",
        label: "件数",
    },
    measure: {
        prop: "",
        label: "体积",
    },
    weight: {
        prop: "",
        label: "货重",
    },
    inputTime: {
        prop: "",
        label: "提箱时间",
    },
    backTime: {
        prop: "",
        label: "返场时间",
    },
    passFlag: {
        prop: "",
        label: "码头放行状态",
    },
    receiveTime: {
        prop: "",
        label: "码头放行时间",
    },
    cpcodeFlag: {
        prop: "",
        label: "码头运抵报告",
    },
    cargoTime: {
        prop: "",
        label: "集港时间",
    },
    total: {
        prop: "",
        label: "累计总数",
    },
};

// 天津
export const ctnDynamicHeaderTJ = {
    ctnno: {
        prop: "",
        label: "箱号",
    },
    // sealno: {
    //     prop: "",
    //     label: "铅封",
    // },
    // dlPortCode: {
    //     prop: "",
    //     label: "装/卸货港",
    // },
    type: {
        prop: "",
        label: "箱型",
    },
    // ctnOwner: {
    //     prop: "",
    //     label: "箱主",
    // },
    // boxStatus: {
    //     prop: "",
    //     label: "箱状态",
    // },
    blnoMain: {
        prop: "",
        label: "主提单号",
    },
    inGateTime: {
        prop: "",
        label: "进场时间",
    },
    // packageNum: {
    //     prop: "",
    //     label: "件数",
    // },
    // measure: {
    //     prop: "",
    //     label: "体积",
    // },
    weight: {
        prop: "",
        label: "货重",
    },
    // total: {
    //     prop: "",
    //     label: "累计总数",
    // },
};

// 厦门
export const ctnDynamicHeaderXM = {
    ctnno: {
        prop: "",
        label: "箱号",
    },
    sealno: {
        prop: "",
        label: "铅封",
    },
    dlPortCode: {
        prop: "",
        label: "装/卸货港",
    },
    type: {
        prop: "",
        label: "箱型",
    },
    ctnOwner: {
        prop: "",
        label: "箱主",
    },
    grossWeight:{
        prop:"",
        label:"箱货重"
    },
    vgm: {
        prop: "",
        label: "VGM",
    },
    customPassTime:{
        prop:"",
        label:"海关放行时间"
    },
    isCustomPass:{
        prop:"",
        label:"海关放行状态"
    },
    portPassTime: {
        prop: "",
        label: "码头放行时间",
    },
    isPortPass:{
        prop:"",
        label:"码头放行状态"
    },
    inGateTime:{
        prop:"",
        label:"进港时间"
    },
    loadTime: {
        prop: "",
        label: "装船时间",
    },
    outGateTime:{
        prop:"",
        label:"出门时间"
    }
   
}

export const preentrydata1 = [
    {
        blno: "",
        boxNo: "",
        listStatText: "",
        totalGrossWt: "",
        totalPackNum: "",
        measure: "",
    },
];
export const mapTypeSelect = [
    {
        title: "卫星混合",
        http: "api.tianditu.gov.cn/v4.0/image/map/maptype/satellitepoi.png",
        layer: window.TMAP_HYBRID_MAP,
    },
    {
        title: "地图", //地图控件上所要显示的图层名称
        icon: "http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png", //地图控件上所要显示的图层图标（默认图标大小80x80）
        layer: window.TMAP_NORMAL_MAP, //地图类型对象，即MapType。
    },
    {
        title: "卫星",
        icon: " http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellite.png",
        layer: window.TMAP_SATELLITE_MAP,
    },
    {
        title: "地形",
        icon: " http://api.tianditu.gov.cn/v4.0/image/map/maptype/terrain.png",
        layer: window.TMAP_TERRAIN_MAP,
    },
    {
        title: "地形混合",
        icon: " http://api.tianditu.gov.cn/v4.0/image/map/maptype/terrainpoi.png",
        layer: window.TMAP_TERRAIN_HYBRID_MAP,
    },
];

// 时间快捷操作
export const SHORT_CUTS = [
    {
        text: "今天",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit("pick", [start, end]);
        },
    },
    {
        text: "最近一周",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
        },
    },
    {
        text: "最近一个月",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
        },
    },
    {
        text: "最近三个月",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
        },
    },
];
