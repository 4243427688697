// 因为物流订阅代码太乱了，所以单独拿出来，这里是物流订阅的详情部分
// 提单信息
export const BLON_INFO_LIST = [
    {
        title: '预录入',
        imgDep: "costcoState",
        show: ["NB"],
        param: "costcoTime",
    },
    {
        title: '进场',
        imgDep: "arrivalTimeState",
        param: "arrivalTime",
        show:['NB','SH','TJ','XM']
    },
    {
        title: '预配舱单',
        imgDep: "preentryState",
        param: 'preentryTime',
        show:['NB','SH','QD','TJ','XM']
    },
   
    {
        title: "清洁舱单",
        imgDep: "ifcsumState",
        show: ["NB"],
    },
    {
        title: "码头运抵",
        imgDep: "cpcodeState",
        show: ["NB",'SH'],
        // 上海,天津需追加:已进港的箱子/总箱数：{{ boxInfo.ctnNum }}
    },
    // {
    //     title:"进港",
    //     imgDep:"portArrivalState",
    //     show:['TJ']
    // },
    {
        title: "三联单标记",
        imgDep: "sldState",
        show: ["NB"],
    },
    {
        title:"提箱",
        imgDep: "inputState",
        param: "inputTime",
        show:['QD']
    },
    {
        title:"返场",
        imgDep:"backState",
        param:"backTime",
        show:['QD']
    },
    {
        title:"集港",
        imgDep: "jgApplyState",
        param: "jgApplyTime",
        show:['QD']
    },
    {
        title: "海关放行",
        imgDep: "isCustomPass",
        param: "customPassTime",
        show: ["NB",'SH','QD','TJ','XM'],
    },
    {
        title: "码头放行",
        imgDep: "passState",
        param: "receiveTime",
        show: ["NB",'SH','XM'],
    },
    {
        title: "装船出港",
        imgDep: "loadState",
        param: "loadTime",
        show: ["NB",'XM'],
    },
]