import {
    qdShipQuery,
    qdShipListPageQuery,
    qdShipSubscribed,
    qdShipBatchTop,
    qdShipBatchCancelTop,
    qdShipUpdateRemark,
    qdShipBatchDelete,
} from "@/api/qingdaoShipQuery";
import {
    tjShipQuery,
    tjShipListPageQuery,
    tjShipSubscribed,
    tjShipBatchTop,
    tjShipBatchCancelTop,
    tjShipUpdateRemark,
    tjShipBatchDelete,
} from "@/api/tianjingShipQuery";
import {
    xmShipQuery,
    xmShipListPageQuery,
    xmShipSubscribed,
    xmShipBatchTop,
    xmShipBatchCancelTop,
    xmShipUpdateRemark,
    xmShipBatchDelete,
} from "@/api/xiamenShipQuery";
import {
    searchVeVo,
    shVesselsSearchVeVo,
    queryShipData,
    queryShVessels,
    toTopSH,
    calToTopSH,
} from "@/api/ship";
import {
    boxDelete,
    shVesselsDelete,
    remarkShVessels,
    updateRemarkAndTruck,
    subcribeInfo,
    shVesselsInfo,
} from "@/api/boxQuery";
import { toTop, cancelToTop } from "@/api/tracking";

/**
 * @description 船期的接口请求，根据不同tab来区分
 * @param  tab NB SH QD TJ
 * @param type 1.查询已订阅的船名航次 2.订阅 3.批量置顶 4.取消置顶 5.批量删除  6.列表  7.修改备注
 */
export const shipRequest = ({ tab, type }) => {
    switch (tab) {
        case "NB":
            switch (type) {
                case 1:
                    return searchVeVo;
                case 2:
                    return subcribeInfo;
                case 3:
                    return toTop;
                case 4:
                    return cancelToTop;
                case 5:
                    return boxDelete;
                case 6:
                    return queryShipData;
                case 7:
                    return updateRemarkAndTruck;
            }
        case "SH":
            switch (type) {
                case 1:
                    return shVesselsSearchVeVo;
                case 2:
                    return shVesselsInfo;
                case 3:
                    return toTopSH;
                case 4:
                    return calToTopSH;
                case 5:
                    return shVesselsDelete;
                case 6:
                    return queryShVessels;
                case 7:
                    return remarkShVessels;
            }
        case "QD":
            switch (type) {
                case 1:
                    return qdShipSubscribed;
                case 2:
                    return qdShipQuery;
                case 3:
                    return qdShipBatchTop;
                case 4:
                    return qdShipBatchCancelTop;
                case 5:
                    return qdShipBatchDelete;
                case 6:
                    return qdShipListPageQuery;
                case 7:
                    return qdShipUpdateRemark;
            }
        case "TJ":
            switch (type) {
                case 1:
                    return tjShipSubscribed;
                case 2:
                    return tjShipQuery;
                case 3:
                    return tjShipBatchTop;
                case 4:
                    return tjShipBatchCancelTop;
                case 5:
                    return tjShipBatchDelete;
                case 6:
                    return tjShipListPageQuery;
                case 7:
                    return tjShipUpdateRemark;
            }
        case "XM":
            switch (type) {
                case 1:
                    return xmShipSubscribed;
                case 2:
                    return xmShipQuery;
                case 3:
                    return xmShipBatchTop;
                case 4:
                    return xmShipBatchCancelTop;
                case 5:
                    return xmShipBatchDelete;
                case 6:
                    return xmShipListPageQuery;
                case 7:
                    return xmShipUpdateRemark;
            }
    }
};
