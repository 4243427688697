// 厦门船期
import { request } from "@/utils/request.js";


/**
 * @description 厦门船期订阅
 * @typedef {Object} param 
 * @property {string} param.code 类型,NB,SH,TJ,XM,这里是厦门
 * @property {string} param.companyId 公司id
 * @property {string} [param.remark] 备注
 * @property {string} param.role 角色 当前用户角色/PERSONAL/ENTERPISE
 * @property {string} param.vesselsName 船名
 * @property {string} param.voyage 航次
 * @property {string} param.wharf 码头
 * @param {param} param
 * @returns 
 */
export const xmShipQuery=(param)=>{
    return request("/vessels/xmVessels/subscribe",'post',param)
}

/**
 * @description 厦门船期列表分页查询
 * @typedef {Object} pageVesselsDTO
 * @property {string} pageVesselsDTO.code 类型,NB,SH,TJ,这里是厦门
 * @property {number} pageVesselsDTO.current 当前页
 * @property {number} pageVesselsDTO.size 每页显示条数
 * @property {string} [pageVesselsDTO.vesselsName] 船名
 * @property {string} [pageVesselsDTO.voyage] 航次
 * @property {string} [pageVesselsDTO.startTime] 开始时间
 * @property {string} [pageVesselsDTO.endTime] 结束时间
 * @property {string} [pageVesselsDTO.ctnApplyBeginTime] 是否是今日开港
 * @property {string} [pageVesselsDTO.ctnApplyEndTime] 是否是今日截港
 * @property {string} [pageVesselsDTO.closeTime] 是否是今日截单/截关
 * @property {string} [pageVesselsDTO.arrivalTime] 是否是今日抵港
 * @property {string} [pageVesselsDTO.leaveTime] 是否是今日离港
 * @param {pageVesselsDTO} param 
 */
export const xmShipListPageQuery=(param)=>{
    return request("/vessels/xmVessels/selectPage",'post',param)
}


/**
 * @description 获取已订阅的船名航次
 * @typedef {Object} param 
 * @property {string} param.code 类型,NB,SH,TJ,这里是厦门
 * @param {param} param
 */

export const xmShipSubscribed=(param)=>{
    return request("/vessels/xmVessels/queryVeVo",'get',param,{
        header:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    })
}


/**
 * @description 批量删除
 * @param {Array<number>} ids
 */
export const xmShipBatchDelete=(ids)=>{
    return request("/vessels/xmVessels/deleteList",'post',ids)
}


/**
 * @description 批量置顶
 * @param {Array<number>} ids
 */
export const xmShipBatchTop=(ids)=>{
    return request("/vessels/xmVessels/toppingList",'post',ids)
}

/**
 * @description 取消置顶
 * @param {Array<number>} ids
 */
export const xmShipBatchCancelTop=(ids)=>{
    return request("/vessels/xmVessels/unToppingList",'post',ids)
}

/**
 * @description 修改备注
 * @param {number} id
 * @param {string} remark
 */
export const xmShipUpdateRemark=(param)=>{
    return request("/vessels/xmVessels/updateRemark",'post',param,{
        header:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    })
}